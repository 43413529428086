import React from "react";

const Footer = () => {
  return (
    <div id="footer">
      <a href="#header">back to top</a>
    </div>
  );
};

export default Footer;
